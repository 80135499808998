@import 'portal-color-pallete.scss';

@function color($key: "primary") {
  @return map-get($colors, $key);
}

$color-interval: 10% !global;
@function color-level($color-name: "primary", $level: 0) {
  $color: color($color-name);
  $color-base: if($level < 0, black, white);

  @return mix($color-base, $color, $level * $color-interval);
}

$opacity-levels: (
  'dark': 0.8, // opacity used with lighter colors
  'middle': 0.6,
  'light': 0.4   // opacity used with darker colors
);
@function color-alpha($name: "primary", $opacity: 0) {
  $color: color($name);

  // Get the named opacity level, if it exists
  @if map-key-exists($opacity-levels, $opacity) {
    $opacity: map-get($opacity-levels, $opacity);
  }

  // Use rgba() to manipulate the color's alpha level
  @return rgba($color, $opacity);
}

$color-primary: color("primary");
$color-accent: color("accent");
$color-secondary: color("secondary");
$color-foreground: color("foreground");
$color-background: color("background");
$color-common: color("common");