@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import "@fontsource/montserrat";
@import "@fontsource/rubik";
@import "@fontsource/source-sans-pro";


html {
    scroll-behavior: smooth;
    font-size: 62.5%;
    /* font-size: 70%; */
    top:0; 
    left:0;
    z-index:-1;
    font-family: "Open Sans";
}

body {
    background: radial-gradient(86.18% 170.43% at 8.99% 48.63%, #1893D7 0%, #B5E1F4 100%);
    background-image: url("./assets/images/mainBackground.jpg");
    background-size: cover;
    min-height: 100vh;
    font-size: 1.6rem;
}

input:-internal-autofill-selected {
    appearance: none !important;
    height: 10px !important;
    width: 100% !important;
}